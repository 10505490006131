
.order-list-card.card
    border: 1px solid $color-bnn-gray-light
    border-radius: 8px

.order-list-item-header
    padding: 12px
    border-bottom: 1px solid $color-bnn-gray-light

.order-list-item-body,
.order-list-item-footer
    padding: 0px 12px

.text-gray-medium-2
    color: $color-bnn-gray-medium-2

.product-items
	display: flex
	flex-direction: column
	gap: 24px

.product-item
    display: flex
    gap: 12px

.product-img {
    width: 48px
    height: 48px

    > img {
      width: 100%
      height: 100%
      object-fit: contain
    }
}

.product-name
  flex: auto

.order-number
    font-weight: bold
    text-transform: uppercase

.order-status
    padding: 2px 4px
    border-radius: 4px
    font-size: 12px
    margin-left: 4px
    background: $color-bnn-gray-medium-2
    color: white
    font-weight: bold

    &.complete
        background: $color-green

    &.process
        background: $color-warning

    &.create
        background: $color-bnn-gray-light-2
        color: $color-bnn-gray-medium

    &.cancel
        background: $color-bnn-red

.label-order-detail
	min-width: 110px
	max-width: 110px
	flex: auto
	color: $color-bnn-gray-medium-2
	&.flex-1
		flex: 1
